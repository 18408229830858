export const products = [
    {
      id: "1",
      category: "Клипсы и Скобы",
      images: "/images/cat/клипсы.png",
      cati:"Клипсы и Скобы"
    },
    {
      id: "2",
      category: "Саморез",
      images: "/images/cat/саморез.png",
      cati:"Саморезы"
    },
    {
      id: "3",
      category: "Расход. материал",
      images: "/images/cat/расход.png",
      cati:"Расходный материал"
    },   
     {
      id: "4",
      category: "Хомуты",
      images: "/images/cat/хамут.jpg",
      cati:"Хомуты"
    },
    {
      id: "5",
      category: "Болты",
      images: "/images/cat/болт.png",
      cati:"Болты"
    },
    {
      id: "6",
      category: "Строй Инструменты",
      images: "/images/cat/строй инст.png",
      cati:"Строительные Инструменты"
    },
    {
      id: "7",
      category: "Глухарь",
      images: "/images/cat/глухарь.png",
      cati:"Глухары"
    },
    {
      id: "8",
      category: "Ручной инструмент",
      images: "/images/cat/ручной.png",
      cati:"Ручные инструменты"
    },
    {
      id: "9",
      category: "Строител. оборуд.",
      images: "/images/cat/стройоборуд.png",
      cati:"Строительные оборудование"
    },
    {
      id: "10",
      category: "Анкер",
      images: "/images/cat/Анкер.png",
      cati:"Анкера"
    },
    {
      id: "11",
      category: "Винты",
      images: "/images/cat/винты.jpg",
      cati:"Винты"
    },
    {
      id: "12",
      category: "Метрический крепеж",
      images: "/images/cat/метри.png",
      cati:"Метрический крепеж"
    },
    {
      id: "14",
      category: "Сварочное оборуд.",
      images: "/images/cat/Сварочная.png",
      cati:"Сварочное оборудование"
    },
    {
      id: "15",
      category: "мебельная фурнитура",
      images: "/images/cat/мебель.png",
      cati:"мебельная фурнитура"
    },
    {
      id: "16",
      category: "Сантехника",
      images: "/images/cat/сантехника.png",
      cati:"Сантехника"
    },
    {
      id: "17",
      category: "Электроинструмент",
      images: "/images/cat/элинст.png",
      cati:"Электроинструмент"
    },
    {
      id: "18",
      category: "Заклепки",
      images: "/images/cat/заклеп.png",
      cati:"Заклепки"
    },
    {
      id: "19",
      category: "Перф. крепёж",
      images: "/images/cat/перф.png",
      cati:"Перфированый крепёж"
    },
    {
      id: "20",
      category: "Крепежный инст.",
      images: "/images/cat/кран.jpg",
      cati:"Крепежные инстструменты"
    },
    {
      id: "21",
      category: "Скотч",
      images: "/images/cat/скотч.png",
      cati:"Скотчы"
    },
    {
      id: "22",
      category: "Зажимы",
      images: "/images/cat/зажимы.png",
      cati:"Зажимы"
    },
    {
      id: "23",
      category: "Дюбелья",
      images: "/images/cat/дюбель.jpg",
      cati:"Дюбелья"
    },
    {
      id: "24",
      category: "Гвозди",
      images: "/images/cat/гвозди.png",
      cati:"Гвозди"
    },
    {
      id: "25",
      category: "С.И.З.",
      images: "/images/cat/СИЗ.png",
      cati:"Средства индувидуальной защиты"
    },
    {
      id: "26",
      category: "Строител. химия",
      images: "/images/cat/стройхимия.png",
      cati:"Строительная химия"
    },
    {
      id: "27",
      category: "Гайки",
      images: "/images/cat/гайка.png",
      cati:"Гайки"
    },
    {
      id: "30",
      category: "Цепи",
      images: "/images/cat/цепи.png",
      cati:"Цепи"
    },
    {
      id: "31",
      category: "Садовый инвентарь",
      images: "/images/cat/садовый.png",
      cati:"Садовый инвентарь"
    },
    {
      id: "32",
      category: "Шурупы",
      images: "/images/cat/шуруп.png",
      cati:"Шурупы"
    },
    {
      id: "33",
      category: "Шайбы",
      images: "/images/cat/шайба.png",
      cati:"Шайбы"
    },
    {
      id: "34",
      category: "Карабины",
      images: "/images/cat/карабин.png",
      cati:"Карабины"
    },
    {
      id: "35",
      category: "замки и фурнитура",
      images: "/images/cat/замки.png",
      cati:"Замки и фурнитура"
    },
    {
      id: "36",
      category: "Авто. оборуд.",
      images: "/images/cat/авто.png",
      cati:"Автомобильная оборудования"
    },
    {
      id: "37",
      category: "Газовое оборудование",
      images: "/images/cat/газ.jpg",
      cati:"Газовое оборудование"
    },
    {
      id: "38",
      category: "Для гипсокартона",
      images: "/images/cat/гипскартон.png",
      cati:"Для гипсокартона"
    },

    {
      id: "39",
      category: "Электрика",
      images: "/images/cat/эл.jpg",
      cati:"Электрика"
    },

  ];