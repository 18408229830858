import { truncateText, truncateText1 } from "../../utils/truncateText";
import { IoIosAddCircle } from "react-icons/io";
import { MdEditSquare } from "react-icons/md";
import { AiTwotoneDelete } from "react-icons/ai";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import Container from "../../components/Container";
import FormWrap from "../../components/FormWrap";
import { addProducts, deleteProduct, editItem, getProductCategory } from "../../api";
import { uploadImageHandleradd } from "../../api";
import {Link} from "react-router-dom";
import Pegination from "../../components/Pagination";


const Adminprod = ({data2}:any) => {



  const [edit, setEdit]=useState({
    prod:true,
    Add:false,
    edit:false
  })
  const [image, setImage] = useState<any>();
  const [data1, setData1]=useState<any>(data2)
  const [searchItem, setSearchItem]=useState<any>('')

  
  const [value, setValue]=useState<any>({
    id:Date.now(),
    name:'',
    description:'',
    price:'',
    category:'',
    brand:'',
    firma:'',
    inStock: true,
    height:'',
    width:'',
    type:'',
    size:'',
    count:''

  })

  const [preview, setPreview] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [postsPerPage, setPostsPerPage] = useState<any>(18);
  const [searcho, setSearcho] = useState('')

  const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts =searcho?.length>1 ? searcho?.slice(firstPostIndex, lastPostIndex): data1?.slice(firstPostIndex, lastPostIndex);

  
  
  const paginate = (pageNumber:any) => setCurrentPage(pageNumber)

  


    const {  formState: { errors } } = useForm<FieldValues >({
        defaultValues: {
            name: '',
            firstname: '',
            phone: '',
        }
    })



      const uploadImageHandler = async (e: any) => {
       
        uploadImageHandleradd(e, setImage);
      
    
      };
      useEffect(() => {
      if(image){
        setPreview([...preview,image])
      }
      }, [image])
      useEffect(() => {

        getProductCategory(setData1)
       
      }, [])
    

  

    function editproduct(e:any, data:any){
        setValue({
          id:data.id,
          name:data.name,
          description:data.description,
          price:data.price,
          category:data.category,
          brand:data.brand,
          firma:data.firma,
          inStock: true,
          height:data.height,
          type:data.type,
          width:data.width,
          size:data.size,
          count:data.count
        })
        setPreview(data.images)

        setEdit({
          prod:false,
          Add:false,
          edit:true
        })
        
        
        setSearcho('')
    }
    async function AddProduct(e:any, data:any, preview:any){
      e.preventDefault();
      const data1={
        name:data.name,
        description:data.description,
        price:data.price,
        category:data.category,
        images:preview,
        brand:data.brand,
        firma:data.firma,
        inStock: true,
        height:data.height,
        type:data.type,
        width:data.width,
        size:data.size,
        count:data.count
      }
      await addProducts(data1)
      setValue({
        name:'',
        description:'',
        price:'',
        category:'',
        brand:'',
        firma:'',
        inStock: true,
        height:'',
        type:'',
        width:'',
        size:'',
        count:''
    
      })
      setImage('')
      setPreview('')
      // getData()
      getProductCategory(setData1)

      setEdit({
        prod:true,
        Add:false,
        edit:false
      })
      
      

    }

   async function ProductEdit(e:any, data:any, preview:any){
      e.preventDefault();
      const data1={
        id:data.id,
        name:data.name,
        description:data.description,
        price:data.price,
        category:data.category,
        images:preview,
        brand:data.brand,
        firma:data.firma,
        inStock: true,
        height:data.height,
        width:data.width,
        type:data.type,
        size:data.size,
        count:data.count
      }
      await editItem(data1)
      await getProductCategory(setData1)
      setValue({
        name:'',
        description:'',
        price:'',
        category:'',
        brand:'',
        firma:'',
        inStock: true,
        height:'',
        width:'',
        type:"",
        size:'',
        count:''
    
      })
      setImage('')
      setPreview('')
      setEdit({
        prod:true,
        Add:false,
        edit:false
      })

    }

    async function Delete(e:any, data:any){
      e.preventDefault();
    await deleteProduct(data.id)
    await getProductCategory(setData1)

    }

    const changeHandler = (e:any) => {
    
      
      setValue({ ...value, [e.target.name]: e.target.value })
    }

    async function search(e:any){
      e.preventDefault()
   
      setSearchItem(value)
      handleSubmit(e, searchItem.toLowerCase())
      // if(searchItem.length>2){
      //   searchItem.toLowerCase()
      //   const result=data2?.filter((prod:any)=>{
    
      //     return prod  && prod?.name?.toLowerCase().includes(searchItem?.toLowerCase())
          
      //   })
      //   setSearcho(result)
      //   setSearchItem('')
      
      // }
      // return searcho
     }
     const handleSubmit=(e:any, value:any)=>{
      e.preventDefault()
      const result=data2.filter((prod:any)=>{
        
        return prod  && prod.name.toLowerCase().includes(value) 
        
      })
      setSearcho(result)
      setSearchItem("")
     }
    
    
     
    
     
    return ( 
        <>
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-2 mt-5">
           <div>
           <input className="ml-20 col-span-1 cursor-pointer border-[1.4px] border-slate-300 text-black rounded-sm p-2 transition hover:scale-105 text-center items-center text-[10px] mb-2 md:text-[15px] mr-3" type="search" value={searchItem} onChange={(e:any)=>setSearchItem(e.target.value)}/>
          <button onClick={(e)=>search(e)}>Поиск</button>
          </div>
          </div>
           <div className="flex mt-4 mb-4 flex-col items-center w-[50px] mx-auto gap-1 cursor-pointer" onClick={()=>setEdit({
    prod:false,
    Add:true,
    edit:false
  })}>
   
                <IoIosAddCircle size={60}/>
        </div>
      
      {edit.prod &&  <Container><div className='grid grid-cols-2  m-3  sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-8'>{currentPosts?.map((data:any, i:number)=>{
            return <div key={i} >
                 <div onClick={()=> {}} className="col-span-1 cursor-pointer border-[1.2px] border-slate-300 bg-slate-200  rounded-sm p-2 transition hover:scale-105 text-center text-sm">
                    <div className="flex justify-around mb-1">
                        <div className="cursor-pointer hover:scale-105" onClick={(e)=>editproduct(e, data)}><MdEditSquare size={20}/></div>
                        <div className="cursor-pointer hover:scale-105" onClick={(e)=>Delete(e, data)}><AiTwotoneDelete size={20}/></div>
            
                    </div>
        <div className="flex flex-col items-center w-full gap-1">
            <div className="aspect-square owerflow-hidden relative w-full border py-2 px-4 rounded flex flex-col items-center">
                <img
                
                width={100}
                src={data?.images[0]}
                alt={data.name}
                className="w-[300px] h-full object-contain max-h-[300px] min-h-[300px] sm:min-h-[300px]"/>
            </div>
        <Link to={`/product/${data?.id}`}>

            <div className="mt-4 text-[11px] md:text-xl">{data.name}</div>
            </Link>
            <div className="text-[10px] text-gray-700 md:text-xl">Размер: {data.width}см*{data.height}см</div>
            <div >Цена: <span className="font-semibold"> {data.price}</span>р</div>
        </div>
         </div>
         </div>
        })}
       </div>
       </Container>

       }
    {edit.Add && <div className='grid bg-black/40 top-0 right-0 bottom-0 left-0' >
    <Container>
        <FormWrap>
        <div >
        <h3 className="flex flex-col gap-1 items-center w-full text-base font-semibold mt-4  md:text-lg">Создать Продукт</h3>
        <div className="imgDiv" >
              <label htmlFor='upload' className=" text-white flex justify-center items-center bg-gray-500 cursor-pointer hover:bg-gray-700 p-2">
              { preview.length ?  <img
              
                  width={160}
                  height={160}
                  alt={preview}
                  className="w-full h-full object-contain"
                  src={preview[0]} />: <p>Загрузит фото</p> }
              </label>
              <input type="file" accept="image/*" id='upload' name='upload' value={""} style={{ display: 'none' }} onChange={uploadImageHandler} />

            </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Названые Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='name' placeholder="Названые" onChange={e => changeHandler(e)} value={value.name===undefined? value : value.name}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Описание Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='description' placeholder="Описание" onChange={e => changeHandler(e)} value={value.description===undefined? value : value.description}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Цена Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="number" name='price' placeholder="Цена" onChange={e => changeHandler(e)} value={value.price===undefined? value : value.price}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Категория Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='category' placeholder="Категория" onChange={e => changeHandler(e)} value={value.category===undefined? value : value.category}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Единица измерения</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='brand' placeholder="кг" onChange={e => changeHandler(e)} value={value.brand===undefined? value : value.brand}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Производитель Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='firma' placeholder="Производитель" onChange={e => changeHandler(e)} value={value.firma===undefined? value : value.firma}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Высота Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='height' placeholder="Высота" onChange={e => changeHandler(e)} value={value.height===undefined? value : value.height}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Ширина Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='width' placeholder="Ширина" onChange={e => changeHandler(e)} value={value.width===undefined? value : value.width}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Размеры Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='size' placeholder="Размеры" onChange={e => changeHandler(e)} value={value.size===undefined? value : value.size}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Количество Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='count' placeholder="Количество" onChange={e => changeHandler(e)} value={value.count===undefined? value : value.count}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Тип материала</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='type' placeholder="Тип материала" onChange={e => changeHandler(e)} value={value.type===undefined? value : value.type}/>
        </div>
        <button className="mt-5 disabled:opacity-70 disabled:cursor-not-allowed rounded-md hover:opacity-80 transition w-[200px ] border-s-slate-700
    flex items-center mx-auto justify-center gap-2 bg-slate-700 py-1 px-2 " onClick={(e)=>AddProduct(e, value, preview)}>Добавить Продукт</button>
        </div>
        </FormWrap>
        </Container>
    </div>}
    {edit.edit && <div className='grid bg-black/40 top-0 right-0 bottom-0 left-0' >
    <Container>
        <FormWrap>
        <div >
        <h3 className="flex flex-col gap-1 items-center w-full text-base font-semibold mt-4  md:text-lg">Создать Продукт</h3>
        {preview &&   <div className="imgDiv" >
              <label htmlFor='upload' className=" text-white flex justify-center items-center bg-gray-500 cursor-pointer hover:bg-gray-700 p-2">
                <img
                
                  width={200}
                  height={200}
                  alt={preview[0]}
                  className="w-full h-full object-contain"
                  src={preview[0]} />
              </label>
              <input type="file" accept="image/*" id='upload' name='upload' value={""} style={{ display: 'none' }} onChange={uploadImageHandler} />

            </div>}
   
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Названые Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='name' placeholder="Названые" onChange={e => changeHandler(e)} value={value.name===undefined? value : value.name}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Описание Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='description' placeholder="Описание" onChange={e => changeHandler(e)} value={value.description===undefined? value : value.description}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Цена Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='price' placeholder="Цена" onChange={e => changeHandler(e)} value={value.price===undefined? value : value.price}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Категория Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='category' placeholder="Категория" onChange={e => changeHandler(e)} value={value.category===undefined? value : value.category}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Единица измерения</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='brand' placeholder="кг" onChange={e => changeHandler(e)} value={value.brand===undefined? value : value.brand}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Производитель Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='firma' placeholder="Производитель" onChange={e => changeHandler(e)} value={value.firma===undefined? value : value.firma}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Высота Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='height' placeholder="Высота" onChange={e => changeHandler(e)} value={value.height===undefined? value : value.height}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Ширина Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='width' placeholder="Ширина" onChange={e => changeHandler(e)} value={value.width===undefined? value : value.width}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Размеры Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='size' placeholder="Размеры" onChange={e => changeHandler(e)} value={value.size===undefined? value : value.size}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Количество Продукта</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='count' placeholder="Количество" onChange={e => changeHandler(e)} value={value.count===undefined? value : value.count}/>
        </div>
        <div className="flex flex-col items-center mt-2">
        <label className="text-white" htmlFor="">Тип материала</label>
        <input className=" w-full p-1 outline-none pg-white  border-2 rounded-md transition disabled:opacity-70 " type="text" name='type' placeholder="Тип материала" onChange={e => changeHandler(e)} value={value.type===undefined? value : value.type}/>
        </div>
        <button className="mt-5 disabled:opacity-70 disabled:cursor-not-allowed rounded-md hover:opacity-80 transition w-[200px ] border-s-slate-700
    flex items-center mx-auto justify-center gap-2 bg-slate-700 py-1 px-2 " onClick={(e)=>ProductEdit(e, value, preview)}>Изменить Продукт</button>
        </div>
        </FormWrap>
        </Container>
     
    </div>}
    {currentPosts?.length && <Pegination  totalPosts={data1?.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage} />}
        
    </>
     );
}
 
export default Adminprod;