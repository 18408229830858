"use client"

import { useEffect, useState } from "react";
import { getProductCategory } from "../api";
import { IoMdSearch } from "react-icons/io";

const SearchBar = ({setSearch, product5}:any) => {

  const [product, setProduct]=useState<any>()
  const [input, setInput]=useState<any>()
  useEffect(() => {

    getProductCategory(setProduct)
  
 }, [input, setSearch])

 const handleSubmit=(e:any, value:any)=>{
  console.log(value);
  
  e.preventDefault()
  const result=product.filter((prod:any)=>{
    
    return prod  && prod.name.toLowerCase().includes(value) || prod.category.toLowerCase().includes(value) 
    
  })
  
  setSearch(result)
 }


  const handleChange=(e:any, value:string)=>{
      e.preventDefault()
      if(!value.trim().length) return;
      setInput(value)
      handleSubmit(e, value)
  }

  return ( <form onSubmit={(e)=>handleChange(e, input)}>   
    <label form="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
    <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <IoMdSearch/>
        </div>
        <input type="search" id="default-search" onChange={(e)=>{handleChange(e, e.target.value);setInput(e.target.value)}}
        className="block w-full p-4 ps-10 text-sm text-gray-900 border rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
         placeholder="Анкер с крючком" required/>
    </div>
</form> );
}
 
export default SearchBar;