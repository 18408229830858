import React, { useState } from "react";
import { useNavigate } from "react-router-dom";



interface Product {
    id: string;
    name: string;
    images: string[];
    width: number;
    height: number;
    price: number;
    brand: string;
    firma: string;
}

interface ProductCardProps {
    data: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ data }) => {
    const router = useNavigate();
    const [loading, setLoading] = useState(false)
    const handleClick = () => {
        router(`/product/${data.id}`);
    };
    setTimeout(() => {
        setLoading(true)
      }, 1000);


      

    return (
        <div onClick={handleClick} className="col-span-1 cursor-pointer border-[1.2px] border-slate-300 bg-slate-200 rounded-sm p-2 line-height: 1 transition hover:scale-105 text-center text-sm ">
           
            <div className="flex flex-col items-center w-full gap-1">
            {loading ? <div className="aspect-square overflow-hidden relative w-full h-[90%]">
                    <img
                        src={data?.images[0]}
                        alt={data?.name}
                        className="w-full h-full object-contain"
                    />
                </div>:     <div className="flex items-center justify-center mx-auto">
        <div aria-label="Loading..." role="status" className="flex items-center space-x-2">
    <svg className="h-10 w-10 animate-spin stroke-black" viewBox="0 0 256 256">
        <line x1="128" y1="32" x2="128" y2="64" strokeLinecap="round" strokeLinejoin="round" strokeWidth="22"></line>
        <line x1="195.9" y1="60.1" x2="173.3" y2="82.7" strokeLinecap="round" strokeLinejoin="round"  strokeWidth="22"></line>
        <line x1="224" y1="128" x2="192" y2="128" strokeLinecap="round" strokeLinejoin="round" strokeWidth="22"></line>
        <line x1="195.9" y1="195.9" x2="173.3" y2="173.3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="22"></line>
        <line x1="128" y1="224" x2="128" y2="192" strokeLinecap="round" strokeLinejoin="round" strokeWidth="22">        </line>
        <line x1="60.1" y1="195.9" x2="82.7" y2="173.3" strokeLinecap="round"  strokeLinejoin="round"      strokeWidth="22"></line>
        <line x1="32" y1="128" x2="64" y2="128" strokeLinecap="round" strokeLinejoin="round" strokeWidth="22"></line>
        <line x1="60.1" y1="60.1" x2="82.7" y2="82.7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="22">
        </line>
    </svg>
      </div> 
      </div>}
                <div className="mt-2 text-[12px] p-1 md:text-xl">{data.name}</div>
                <div className="text-[11px] md:text-[15px]">Цена: <span >{data.price}</span>р за {data.brand}</div>
                <div className="text-[9px] text-gray-700 md:text-[10px]">Производитель: {data?.firma}</div>

            </div>
        </div>
    );
};

export default ProductCard;
