
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchProduct } from "../../store/ActionCreators";
import Container from '../../components/Container'
import { useEffect, useRef, useState } from "react";
import ProductCard from '../../components/products/ProductCard'
import { truncateText } from '../../utils/truncateText';
import { products } from "../../utils/products";
import PaginationControls from '../../components/PaginationControls';
import { useNavigate } from 'react-router-dom'
import SearchBar from '../../components/SearchBar';
import Loading from '../../components/Loading';
import HomeBanner from "../../components/HomeBanner";

export default function HomePages() {

  const router = useNavigate()







  const { loading, product } = useAppSelector(state => state.productDetailReducer)

  const [product1, setProduct1] = useState()
  const [product2, setProduct2] = useState()
  const [product3, setProduct3] = useState()
  const [product4, setProduct4] = useState()
  const [category, setCategory] = useState([])
  const [categori, setCategori] = useState(false)
  const [seting, setSeting] = useState(true)
  const [prod, setprod] = useState(true)
  const [prod1, setprod1] = useState(true)
  const [loading2, setLoading2] = useState(false)
  const [need, setNeed] = useState(false)
  const [width, setWidth] = useState([])
  const [width2, setWidth2] = useState()
  const [categoryName, setCategoryName] = useState('all')
  const [search, setSearch] = useState('')
  const [width3, setWidth3] = useState(false)
  const [type, setType] = useState([])
  const [loading1, setLoading] = useState(false)
  const [oneType, setOneType] = useState()
  const dispatch = useAppDispatch()
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [cati, setCati]=useState(false)
  const [input, setInput] = useState("")



  useEffect(() => {
    dispatch(fetchProduct())
    setTimeout(() => {
      setLoading2(true)
    }, 800);
  }, [dispatch])


  useEffect(() => {
    setProduct1(product)
    setProduct2(product)
  }, [product])


  useEffect(() => {
    if (product1?.length > 0) {
      setLoading(false)
    }
    const item = getcategory(product1, "category")
    setCategory(item)


  }, [product1])

  useEffect(() => {
    if (categoryName !== 'all') {
      let arr;
      let brr= [];

      product1?.map((el) => {
        if (el.category === categoryName) {
          brr.push(el.type)

          arr = brr.map((el) => {
            return el
          })
          arr = [... new Set(arr)]


          return (arr = [... new Set(arr)])
        }

      })

      setType(arr);
      setOneType(arr);
    } else {
      const item = getcategory(product1, "category")
      setCategory(item)
      setType([])
    }
  }, [categoryName])


  function getWidth(oneType) {
    let arr;
    let brr = [];

    product1?.map((el) => {
      if (el.category === categoryName && el.type === oneType) {

        brr.push((el.width))
        arr = brr.map((el) => {

          return el
        })
        arr = [... new Set(arr)]


        return (arr = [... new Set(arr)])
      }


    })
    setWidth(arr);
    setWidth2(arr)
    setWidth3(true)

  }


  function getcategory(products, field) {
    let newElement = products?.map((curtelement) => {
      return curtelement[field]
    })
    return (newElement = [...new Set(newElement)])

  }

  useEffect(() => {

    if (search?.length >= 1) {
      setCati(false)
      setProduct1(search)
      setCategori(false)
      setWidth3('')
       setNeed(false)
       setCategoryName("all");

    }

  }, [search])
  const componentRef = useRef(null); 
 
 
  useEffect(() => { 
 
    if (componentRef.current ) { 
      componentRef.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "start", 
      }); 
    } 
  }, [currentPage,oneType, need, cati]);



  async function getDate() {
    getcategory(product1, categoryName)
    setCategori(!categori)
    router('/')
  }



 async function filtered(item) {
  setCati(false)
    setLoading(true);
    setProduct2([])
    let newTodo = []
    product2?.map((todo) => {
      if (todo.category === item)
        newTodo.push(todo)
      return newTodo
    })
    setLoading(false);

    await setProduct2(newTodo)
        setNeed(true)
  }

  function typeFilter(el) {
    setLoading(true);
    setProduct3([])
    let newTodo = []
    product2?.map((todo) => {
      if (todo.type === el)
        newTodo.push(todo)
      return newTodo
    })
    setWidth3(false)
    setprod(false)
    setLoading(false);
    return setProduct3(newTodo)



  }

  function widthFilter(item) {
    setLoading(true);
    setProduct4([])
    let newTodo = []
    product3?.map((todo) => {
      if (todo.width === item)
        newTodo.push(todo)
      return newTodo
    })
    setprod1(false)
    setprod(false)
    setLoading(false);
    return setProduct4(newTodo)

  }


  function autoCategory() {
    getDate();
    setProduct1(product);
    setWidth3(false);   
    setCategori(true);
    setSearch('');
    setInput('');
    setNeed(false);
    setWidth3('');
    setWidth2('');
    setOneType(type); 
    setprod(true); 
    setCategoryName("all");
    setProduct3([]);
    setProduct2(product) 
  }

  function autoCategory2() {
    getDate();
    setProduct1(product);
    setWidth3(false);   
    setCategori(true);
    setSearch('');
    setInput('');
    setNeed(false);
    setWidth3('');
    setWidth2('');
    setOneType(type); 
    setprod(true); 
    setCategoryName("all");
    setProduct3([]);
    setProduct2(product) 
    setCati(!cati)
  }


  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  // const [currentPosts,setCurrentPosts] = useState(product1?.slice(firstPostIndex, lastPostIndex))
  const currentPosts = product1?.slice(firstPostIndex, lastPostIndex)
  // useEffect(()=>{
  //  let newProduct = product1?.slice(firstPostIndex, lastPostIndex);
  //  setCurrentPosts(newProduct)
  // },[product1])




  return (
    <div className='p-4'>
            <div className="travel" ref={componentRef}></div>
      {
        loading2 ?<Container>
   
        <div className="col-span-1 mt-5  mb-5">
          <SearchBar setSearch={setSearch} product5={product} />
        </div>
        <div>
          <HomeBanner />
       
        </div>

        {!loading1 ? 
        <>
       
          {seting && 
          <div className="col-span-1 cursor-pointer border-[1.4px] border-slate-300 bg-red-400 text-white rounded p-2 transition 
          hover:scale-105 text-center items-center mb-5 md:text-[15px] md:w-[300px] md:mx-auto" >{type?.length === 0 ? <div  onClick={() => { autoCategory2()  }}>Каталог Товаров </div> : <div onClick={()=>{autoCategory2(); setCati(false)} }>Сбросить фильтр  </div>}</div>}
          {/* <div className="col-span-1 cursor-pointer border-[1.4px] border-slate-300 bg-red-400 text-white rounded p-2 transition 
        hover:scale-105 text-center items-center text-[10px] mb-5 md:text-[15px] md:w-[300px] md:mx-auto"  onClick={() => { autoCategory()  }}>{type?.length === 0 ? <>Каталог Товаров</> : <>Сбросить фильтр</>}</div> */}
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-9 gap-4">
            {/* {categori && type?.length === 0 && <><div className="col-span-1 cursor-pointer border-[1.4px] border-slate-300 bg-red-300 text-white rounded-sm p-2 transition 
        hover:scale-105 text-center items-center text-[10px] mb-5 md:text-[15px]"  onClick={() => setCategoryName("all")}>Все</div>
              {category?.map((item, i) => <div key={i} ><div className="col-span-1 cursor-pointer border-[1.4px]
         border-slate-300 bg-red-300 text-white rounded-sm p-2 transition hover:scale-105 text-center items-center text-[10px] mb-5 md:text-[15px]"
                onClick={() => { setCategoryName(item); filtered(item) }}>{truncateText(item)}</div></div>)}</>} */}


              {category?.length > 0 && cati && products?.map((item, i) => <div key={i} onClick={() => { setCategoryName(item.category); filtered(item.category);  setCati(false) }}>
                
              <div className="col-span-1 cursor-pointer border-[1.2px] border-slate-300 bg-slate-200 rounded-sm p-1 transition hover:scale-105 text-center text-sm">
            <div className="flex flex-col items-center w-full gap-1 h-[150px]">
            <div className="aspect-square overflow-hidden relative w-full h-[70%]">
                    <img
                        src={item?.images}
                        alt={item?.category}
                        className="w-full h-full items-center object-contain"
                    />
                </div>
                <p className="p-1">{item.cati}</p>
              
            </div>
        </div>
                </div>)}





            {!width3 && type?.length > 0 && <>
              {type?.length >= 0 && <div className="col-span-1 text-sm h-[60px]
         border-slate-300 bg-orange-400 text-white rounded-sm p-[5px] w-full transition hover:scale-105 text-center items-center md:text-[15px] opacity-90"  onClick={() => { setOneType(type); setprod(true); setCategoryName("all"); setProduct3([]); setProduct2(product1); setCati(true) }}> Назад </div>}
              {type?.map((item, i) => <div key={i} ><div className="col-span-1 text-sm h-[60px]
         border-slate-300 bg-orange-400 text-white rounded-sm p-[5px] pt-[10px] w-full transition hover:scale-105 text-center items-center md:text-[15px] opacity-90"
                onClick={() => { setOneType(item); typeFilter(item); getWidth(item) }}>{item}</div></div>)}
                </>}
            {width3 && width?.length > 0 && <>
              {width?.length >= 1 && <div className="col-span-1 gap-2 cursor-pointer border-[1.4px] border-slate-300 bg-orange-400 text-white rounded-sm p-2 transition 
        hover:scale-105 text-center items-center text-[13px] mb-5 md:text-[15px]"  onClick={() => { setWidth2(width); setprod1(true); setWidth3(false); setProduct4([]) }}>Назад</div>}
              {width?.map((item, i) => <div key={i} ><div className="col-span-1 cursor-pointer border-[1.4px]
         border-slate-300 bg-orange-400 text-white rounded-sm p-2 transition hover:scale-105 text-center items-center text-[13px] mb-5 md:text-[15px]"
                onClick={() => { setWidth2(item); widthFilter(item) }}>{item}</div></div>)}
                </>}

          </div>
          <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-8 mt-3'>
            <>{need && !cati ? <><>{category?.length > 0 && prod && product2?.map((product) => {

              return <ProductCard data={product} key={product.id} />
            })} </>
              <> {type?.length > 0 && prod1 && product3?.map((product) => {

                return <ProductCard data={product} key={product.id} />
              })} </>
              <> {width?.length > 0 && width3 && product4?.map((product) => {

                return <ProductCard data={product} key={product.id} />
              })} </>

            </>
              : currentPosts?.map((product) => {
                if (categoryName === "all" && !cati) {
                  return <ProductCard data={product} key={product.id} />
                }
              })}
            </>

          </div>

          {!need && !cati && <PaginationControls
            totalPosts={product1?.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />}
        </> : <Loading />}
      </Container>:<Loading/>
      }
      
    </div>
  )

}