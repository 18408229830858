import Button from "../../components/Button"
import ProductImage from "../../components/products/ProductImage"
import SetQuantity from "../../components/products/SetQuantity"
import { useCart } from "../../hooks/useCart"
import { useNavigate } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import { MdChangeCircle } from "react-icons/md"
import { toast } from "react-hot-toast";
import { getProductCategoryById } from "../../api"

interface ProductDetailProps{
    productId:any
}

export type CartProductType = {
    id: string,
    name:string,
    description:string, 
    category:string,
    price: number,
    brand: string,
    firma: string,
    inStock: boolean,
    images: any,
    width:number,
    height:string,
    size:string,
    type:string,
    quantity: number
}

const Horizontal=()=>{
    return <hr className="w-[90%] my-2"/>
}


const ProductDetails:React.FC<ProductDetailProps> = (productId:any) => {

    

    const {handleAddProductCart, cartProducts}=useCart()
    
    const router=useNavigate()
    const [isProductInCart, setIsProductInCart]=useState(false)
    const [product,setProduct]=useState<any>([])
    const [cartProduct, setCartProduct]=useState<CartProductType>( {
        id:product?.id,
        name:product?.name,
        description:product?.description, 
        category:product?.category,
        price: product?.price,
        brand: product?.brand,
        firma: product?.firma,
        inStock: product?.inStock,
        images: product?.images,
        width:product?.width,
        height:product?.heigth,
        size:product?.size,
        type:product?.type,
        quantity: 1
    })
    useEffect(() => {
 
         getProductCategoryById(setProduct,setCartProduct,productId?.productId)
       
      }, [])


   



      useEffect(()=>{
        setProduct( {
            id:product?.id,
            name:product?.name,
            description:product?.description, 
            category:product?.category,
            price: product?.price,
            brand: product?.brand,
            firma: product?.firma,
            inStock: product?.inStock,
            images: product?.images,
            width:product?.width,
            height:product?.heigth,
            size:product?.size,
            quantity: 1,
            type:product?.type
        })
      },[])

 

    useEffect(()=>{
        setIsProductInCart(false)

        if(cartProducts){
            const existingIndex=cartProducts?.findIndex((item)=>item?.id===product?.id)
            if(existingIndex > -1){
                setIsProductInCart(true)
            }
        }
    },[cartProducts])

    const handleIamgeSelected=useCallback((value:any)=>{
        setCartProduct((prev)=>{
            return {...prev, images:value}
        })
    },[cartProduct?.images])

    function mek(){
        toast.success("Товар Добавлен в Корзину")
    }

    const handleQtyDecrase=useCallback(()=>{
        if(cartProduct.quantity===1){
            return
        }
        setCartProduct((prev)=>{
            return {...prev, quantity: --prev.quantity}
        })
    }, [cartProduct])
    const handleQtyIncrase=useCallback(()=>{
        if(cartProduct.quantity===99){
            return
        }
        setCartProduct((prev)=>{
            return {...prev, quantity:1+prev.quantity}
        })
    }, [cartProduct])

    
    return ( <>
    
  {product?.id &&  <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mt-4">
       <div><ProductImage cartProduct={cartProduct} product={product} handleIamgeSelected={handleIamgeSelected} /></div>
       <div className="flex flex-col gap-1 text-slate-500 text-sm">
        <h2 className="text-2xl md:text-3xl font-medium  text-slate-700">{product?.name}</h2>
        <Horizontal/>
        <div className=" flex font-semibold"><span className="mr-3" >ЦЕНА:</span><h2>{product?.price} ₽/{product?.brand}</h2></div>
        <Horizontal/>
        <div className="flex"><span className="font-semibold mr-2">КАТЕГОРИЯ:</span><h2> {product?.category} </h2></div>
        <Horizontal/>
        <div className="flex"><span className="font-semibold mr-2">Тип Изделия:</span><h2 > {product?.category} {product?.type} </h2></div>
        <Horizontal/>
        {product?.size && <div className="flex"><span className="font-semibold mr-2">Размер продукта:</span><h2>{product?.size}</h2></div>}
        {product?.height && <div className="flex"><span className="font-semibold mr-2">Висота продукта:</span><h2>{product?.height} см</h2></div>}
        {product?.width && <div className="flex"><span className="font-semibold mr-2">Ширина продукта:</span><h2>{product?.width} см</h2></div>}
        
        <Horizontal/>
        <div className="flex"><span className="font-semibold mr-2">ПРОИЗВОДИТЕЛЬ:</span><h4 className="text-[12px] md:text-[16px]">{product?.firma} </h4></div>
        <Horizontal/>
        <div className="flex"><div className="font-semibold mr-2">ОПИСАНЫЕ:</div><h3>{product?.description}</h3></div>
        <Horizontal/>
        <div className={product?.count > 0 ? "text-teal-400": "text-rose-400"}>{product?.count > 0 ? "Продукт в наличий" : "В Данный момент продукт закончился"}</div>

        <Horizontal/>
        {isProductInCart ? <>
        
        <p className="mb-2 text-slate-500 flex items-center gap-1">
            <MdChangeCircle className="text-teal-400" size={22} />
            <span>Товар Добавлен в Корзину</span>
        </p>
        <div className="max-w-[300px]">
          <Button label="Перейти корзину" outline onClick={()=>{
            router('/cart')
          }}/>
        </div>
        </>:<>
        <SetQuantity cartProduct={cartProduct} handleQtyDecrase={handleQtyDecrase} handleQtyIncrase={handleQtyIncrase} />
        <Horizontal/>
        <div className="max-w-[300px]">
            {product?.inStock===true ? <Button label="Добавить В Корзину" onClick={()=>{handleAddProductCart(cartProduct); mek()}} />:<Button label="Вернуться в магазин" back onClick={()=>{router('/')} }/>}
        </div>
        </>}
       </div>
    </div>} </>);
}
 
export default ProductDetails;